import React from 'react'

import ContactForm from './ContactForm'
import ContactInfo from './ContactInfo'

import './contact.scss'

const Contact = () => {
  return (
    <div className="container">
      <div className="contact-wrap">
        <div className="contact-form-wrap">
          <ContactForm />
        </div>
        <div className="contact-info-wrap">
          <ContactInfo />
        </div>
      </div>
    </div>
  )
}

export default Contact
