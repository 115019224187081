import React from 'react'
import { injectIntl } from 'react-intl'

import './contact-info.scss'
import AnimateIn from '../../AnimateIn'

const ContactInfo = ({ intl }) => {
  const email = intl.formatMessage({ id: 'contact.email.address' })
  const tel = intl.formatMessage({ id: 'contact.telephone' })
  const telTrim = tel.replace(/\s+/g, '')

  return (
    <div className="contact-info">
      <AnimateIn type="fadeLeft">
        <div className="info">
          <div>{intl.formatMessage({ id: 'title' })}</div>
          <address>
            {intl.formatMessage({ id: 'contact.address.1' })}
            <br />
            {intl.formatMessage({ id: 'contact.address.2' })}
          </address>
          <div>
            <a href={`mailto:${email}`}>{email}</a>
          </div>
          <div>
            <a href={`tel:${telTrim}`}>{tel}</a>
          </div>
        </div>
      </AnimateIn>
    </div>
  )
}

export default injectIntl(ContactInfo)
