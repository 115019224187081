import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import Recaptcha from 'react-google-recaptcha'

import Input from './Input'

import './contact-form.scss'

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

class ContactForm extends Component {
  initialState = {
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    question: '',
    errors: [],
    formSent: false,
  }

  state = this.initialState

  recaptchaRef = React.createRef()

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleRecaptcha = value => {
    this.setState({ 'g-recaptcha-response': value })
  }

  validateForm = () => {
    let errors = []

    if (!this.state['g-recaptcha-response']) {
      errors.push('recaptcha')
    }

    if (this.state.firstName.trim().length < 1) {
      errors.push('firstName')
    }

    if (this.state.lastName.trim().length < 1) {
      errors.push('lastName')
    }

    if (!validateEmail(this.state.email)) {
      errors.push('email')
    }

    if (this.state.tel.trim().length < 1) {
      errors.push('tel')
    }

    if (!errors.length) return true

    this.setState({ errors })
    return false
  }

  handleSubmit = e => {
    e.preventDefault()

    if (this.validateForm()) {
      const form = e.target

      const data = {
        'form-name': form.getAttribute('name'),
        ...this.state,
      }

      // Remove unneeded fields in request
      delete data.errors
      delete data.formSent

      fetch(form.getAttribute('action'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode(data),
      })
        .then(() => {
          this.setState({
            ...this.initialState,
            formSent: true,
          })

          this.recaptchaRef.current.reset()
        })
        .catch(error => console.error(error))
    }
  }

  render() {
    const { intl } = this.props

    return (
      <div className="contact-form">
        {this.state.formSent ? (
          <p className="form-sent">
            {intl.formatMessage({ id: 'contact.form.success' })}
          </p>
        ) : (
          <form
            action={`/${intl.locale}/contact/`}
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-recaptcha="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
            noValidate
          >
            <div className="row">
              <div className="col">
                <Input
                  name="firstName"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'contact.first.name' })}
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  required
                  error={this.state.errors.indexOf('firstName') > -1}
                />
              </div>
              <div className="col">
                <Input
                  name="lastName"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'contact.last.name' })}
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  required
                  error={this.state.errors.indexOf('lastName') > -1}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Input
                  name="email"
                  type="email"
                  placeholder={intl.formatMessage({ id: 'contact.email' })}
                  value={this.state.email}
                  onChange={this.handleChange}
                  required
                  error={this.state.errors.indexOf('email') > -1}
                />
              </div>
              <div className="col">
                <Input
                  name="tel"
                  type="tel"
                  placeholder={intl.formatMessage({ id: 'contact.tel' })}
                  value={this.state.tel}
                  onChange={this.handleChange}
                  required
                  error={this.state.errors.indexOf('tel') > -1}
                />
              </div>
            </div>
            <textarea
              name="question"
              placeholder={intl.formatMessage({ id: 'contact.question' })}
              value={this.state.question}
              onChange={this.handleChange}
            />
            <div className="recaptcha">
              <Recaptcha
                ref={this.recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                onChange={this.handleRecaptcha}
              />
            </div>
            <div className="submit">
              <button type="submit">
                {intl.formatMessage({ id: 'contact.submit' })}
              </button>
            </div>
          </form>
        )}
      </div>
    )
  }
}

// Basic email validation check
function validateEmail(email) {
  const regex = RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}')
  return regex.test(email)
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default injectIntl(ContactForm)
