import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'react-intl'
import { withIntl } from 'i18n'

import Layout from '../components/Layout'
import SEO from '../components/Layout/SEO'
import Hero from '../components/Hero'
import ContentBlock from '../components/ContentBlock'

import Contact from '../components/Contact'

const ContactPage = ({ data, intl }) => {
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'contact' })}
        description={intl.formatMessage({ id: 'description.contact' })}
      />

      <Hero
        className="contact"
        title={intl.formatMessage({ id: 'contact.title' })}
        image={data.image}
        quote={{
          text: intl.formatMessage({ id: 'contact.quote' }),
          author: intl.formatMessage({ id: 'contact.quote.author' }),
        }}
      />

      <ContentBlock
        title={intl.formatMessage({ id: 'contact' })}
        className="contact"
      >
        <Contact />
      </ContentBlock>
    </Layout>
  )
}

export default withIntl(injectIntl(ContactPage))

export const ContactQuery = graphql`
  query {
    image: file(relativePath: { eq: "contact-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
